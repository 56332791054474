.main-page {
    height: 100vh;
}

.net {
    position: absolute;
    z-index: -5;
}

.net1 {
    left: 0;
    top: 0;
    width: 224px;
}

.net2 {
    right: 0;
    bottom: 0;
    width: 184px;
}

.main-page .container {
    display: flex;
    flex-direction: column;
}

.main-title {
    font-family: 'Lacquer', sans-serif;
    font-size: 36px;
    line-height: 1;
    text-align: center;
    color: #b293ea;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-bottom: 24px;

    span {
        line-height: 1.25;
        color: #2c1a4a;
        margin-bottom: 8px;
    }
}

p {
    text-align: center;
    line-height: 1.38;
    margin-bottom: 8px;
}

.ru .main-title {
    font-size: 22px;
}

.wr-slider {
    // flex-grow: 1;
    margin-top: 3vh;
    margin-bottom: 6vh;
}

.wr-slider img {
    width: 100%;
}

.wr-slider .slick-list,
.wr-slider .slick-slider {
    position: relative;
}

.wr-slider .slick-dots li {
    width: 12px;
    height: 12px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    vertical-align: middle;
    margin: 0 10px;
}

.wr-slider .slick-dots button {
    width: 100%;
    height: 100%;
}

.wr-slider .slick-dots button {
    background-color: #c3e748;
    border-radius: 6px;
    margin: 0;
}

.wr-slider .slick-dots .slick-active button {
    background-color: #c3e748;
}

.wr-slider .slick-dots .slick-active {
    width: 64px;
}

.wr-slider .slick-dots {
    bottom: -54px;
}

.slide {
    display: flex;
    border-radius: 64px;
    border: solid 16px transparent;
    width: 360px;
    height: 360px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto;

    img {
        width: 100%;
        height: 100%;
    }
}

.slick-current {
    .slide {
        border-color: #c3e748;
    }
}

// .slide-container {
//     padding: 8px 0;
// }

// .slick-slide {
//     margin: 0 15px;
// }

// .slick-list {
//     margin: 0 -15px;
// }

.slide-item {
    margin: 0 7px 14px;
}

.slide-item h2 {
    font-size: 16px;
    font-weight: bold;
    font-style: oblique;
    line-height: 1;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
}

.slide-image-container {
    max-width: 140px;
    border-radius: 16px;
    overflow: hidden;
}

.slide-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slide-item-active {
    position: relative;
}

.slide-item-active .slide-image-container {
    box-shadow: 0 0 120px 0 #7ecd21;
    border: 2px solid #7ecd21;
    box-sizing: border-box;
    max-width: 200px;
}

.btn-upload-foto {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    max-width: 320px;
    width: 90%;
    height: 80px;
    border-radius: 100px;
    background-color: #6e25ff;
    display: block;
    flex-shrink: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin: 0 auto;

    &:hover {
        opacity: .8;
    }
}

.btn-another-foto {
    font-size: 15px;
    font-weight: bold;
    color: #f2bc19;
    display: flex;
    align-items: center;
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    &:hover {
        opacity: .6;
    }
}

.create-page h2 {
    font-weight: bold;
    color: #333;
    line-height: 1.38;
    text-align: center;
    margin-bottom: 20px;
}

.create-page .create-steps {
    line-height: 1.38;
    text-align: center;
    color: #999;
    margin-bottom: 8px;
}

.creatives {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 30px;
}

.creative {
    width: 48%;
    position: relative;
    margin: 0 0 15px;
}

.creative .num-check-foto {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #05b0ff;
    display: none;
    align-items: center;
    justify-content: center;
}

.creative .holder {
    position: relative;
    margin-bottom: 8px;
    padding-top: 100%;
    background-color: #eeeeee;
}

.creative.active .num-check-foto {
    display: flex;
}

.creative img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.creative.active img {
    // box-shadow: 0 0 40px 0 #c77dff;
    border: solid 3px #05b0ff;
    box-sizing: border-box;
}

.creative p {
    font-size: 16px;
    line-height: 1;
    text-align: center;
    color: #333;
    text-transform: capitalize;
}

.create-page .btn-upload-foto {
    position: fixed;
    bottom: 15px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.create-page .btn-upload-foto.disabled {
    background-color: #3b3c3e;
}

.collage-page {
    display: flex;
    flex-direction: column;
    // height: 94vh;
    // overflow: hidden;
    padding-bottom: 20px;
    .btn-upload-foto {
        color: #222;    
        background-color: #c3e748;
        max-width: 240px;
        height: 48px;
        font-size: 16px;
    }
    .btn-back {
        position: absolute;
        left: 56px;
        top: 40px;
        font-size: 18px;
        font-weight: 600;
        color: #2c1a4a;
        padding: 0; 
        margin: 0;

        svg {
            width: 10px;
            margin-right: 8px;
            fill: #2c1a4a;
        }
    }

    .btns-container  {
        max-width: 450px;
        margin: 0 auto;
        width: 100%;
    }
}

.btns-container {
    position: relative;
}

.btn-refresh-foto {
    font-size: 10px;
    color: #6e25ff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: -5px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    padding: 5px;
    svg {
        width: 24px;
        margin-bottom: 6px;
    }
}

.collage-page .creative-holder {
    position: relative;
    max-width: 450px;
    width: 100%;
    margin: 0 auto 24px;

    .creative-holder-placeholder {
        padding-top: 100%;
    }

    .wait-video, video, img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 20;
    }

    .imageview-host {
        background-color: transparent;
        z-index: 20;
    }

    .imageview-holder {
        border-radius: 80px;
    }

    .wait-video {
        z-index: 5;
    }
}

.collage-page img,
.collage-page video {
    width: 100%;
    max-width: 450px;
    // max-height: 450px;
    height: 100%;
    display: block;
    margin: 0 auto;
}

.collage-page img {
    object-fit: cover;
}
.collage-page video {
    object-fit: contain;
}

.collage-page .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 40px;
}

.collage-page-content {
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.collage-page-text {
    margin-bottom: 27px;
}

/*loader*/
.loader {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    position: relative;
}

.collage-page-text {
    margin-bottom: 27px;
}

.loader p {
    font-size: 24px;
    line-height: 1.25;
    text-align: center;
    position: absolute;
    top: calc(55% + 220px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
}

.spinner-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 240px;
    height: 240px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
}

.spinner-container .circle {
    background-color: #F6F6F6;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 240px;
    height: 240px;
    border-radius: 50%;
}

.avatar-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 240px;
    height: 240px;
    box-sizing: border-box;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    animation-name: fadein;
    animation-duration: 320ms;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

.spinner-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.triple-spinner {
    display: block;
    position: relative;
    width: 520px;
    height: 520px;
    border-radius: 50%;
    border: solid 2px #dfdfe0;
}

.triple-spinner2 {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    border: solid 2px #dfdfe0;
}

.triple-spinner::before,
.triple-spinner2::before  {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 2px solid transparent;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
}

.triple-spinner::before {
    border: 16px solid #c3e748;
    border-bottom-color: transparent;
    border-right-color: transparent;
    animation: spin-reverse 1.5s linear infinite;
}

.triple-spinner2::before {
    border: 16px solid #c3e748;
    border-top-color: transparent;
    border-left-color: transparent;
    animation: spin 3s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes spin-reverse {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

/*modal*/
.-show-popup {
    overflow: hidden;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 300;
}

.modal {
    max-width: 380px;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 18px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 0 16px 0 #fff;
    overflow: hidden;
    padding: 100px 26px 16px;
    svg {
        position: absolute;
        top: 0;
        right: -2px;
        left: -2px;
        width: calc(100% + 4px);
        z-index: -5;
    }
    img {
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-top: -40px;
    }
    p {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: #000;
        margin-bottom: 24px;
    }
    .btn-upload-foto {
        color: #fff;
        max-width: 100%;
        width: 100%;
    }
    h3 {
        font-family: 'Lacquer';
        font-size: 36px;
        line-height: 1.25;
        text-align: center;
        color: #0e0e0e;
        margin-bottom: 14px;
    }
}

/*error-page*/
.error-page .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 94vh;
}

.error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.38;
}

.error-page svg {
    max-width: 264px;
    margin-bottom: 5.5vh;
}

.error-page h3 {
    font-weight: bold;
    margin-bottom: 16px;
}

.btn-back {
    font-size: 15px;
    font-weight: bold;
    color: #f2bc19;
    display: flex;
    align-items: center;
    background-color: transparent;
    position: relative;
    top: -5px;
    left: -5px;
    padding: 5px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-bottom: 9px;
}

.btn-back svg {
    width: 18px;
    margin-right: 16px;
}

.collage-page h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.38;
    text-align: center;
    color: #fff;
    width: 100%;
    margin-bottom: 16px;
    text-transform: uppercase;
}

.share-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    position: relative;
    // left: -30px;
    // width: calc(100% + 60px);
    padding-top: 12px;
    padding-bottom: 12px;
    // border-top: 1px solid #d2d2d2;
    // border-bottom: 1px solid #d2d2d2;
    // margin-top: 10px;
}

.share-container button {
    width: 40px;
    height: 40px;
    border: none;
    background: no-repeat;
    flex-shrink: 0;
    margin-left: 24px;

    &:last-child {
        width: 1px;
    }
}

.share-container svg {
    width: 100%;
}

.steps-progress {
    position: fixed;
    height: 4px;
    width: 100%;
    background-color: #e7e7e7;
    top: 0;
    left: 0;
    z-index: 50;

    span {
        transition: 500ms;
        background-color: #feca28;
        display: block;
        height: 100%;
    }
}

.tabs-container {
    display: flex;
    max-width: 450px;
    margin: 0 auto 5vh;
    width: 100%;

    button {
        font-family: 'Helvetica', sans-serif;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.38;
        text-align: center;
        text-transform: uppercase;
        color: #6e25ff;
        flex-grow: 1;
        flex-shrink: 1;
        background: none;
        box-sizing: border-box;
        position: relative;
        padding-bottom: 8px;
    }

    .active-tab {
        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 6px;
            border-radius: 8px;
            background-color: #6e25ff;
        }
    }
}

.wait-video {
    overflow: hidden;
    max-width: 450px;
    margin: 0 auto;

    img {
        filter: blur(7px);
    }
}

.loader-video {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
}

.loader-video .item-loader {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    animation: item-loader .9s linear infinite;
    margin: 0 6px;
}

.loader-video .first-item-loader {
    background-color: #1db8ff;
}

.loader-video .second-item-loader {
    background-color: #ffd300;
    animation: item-loader .9s linear infinite;
    animation-delay: .3s;
}

.loader-video .third-item-loader {
    background-color: #fc4700;
    animation: item-loader .9s linear infinite;
    animation-delay: .6s;
}


@keyframes item-loader {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.8, 1.8);
    }
}

.btn-choice-template {
    width: 68px;
    height: 68px;
    border-radius: 24px;
    flex-shrink: 0;
    margin-right: 16px;
    background-size: contain;

    &.active {
        box-shadow: 0 0 0 4px #c3e748 inset;
    }

    @media all and (max-width: 600px) {
        width: 52px;
        height: 52px;
        border-radius: 16px;
    }

    &.btn-choice-template--s6132 { background-image: url(/assets/img/tabs/6132.jpeg); }
    &.btn-choice-template--s6133 { background-image: url(/assets/img/tabs/6133.jpeg); }
    &.btn-choice-template--s6134 { background-image: url(/assets/img/tabs/6134.jpeg); }
    &.btn-choice-template--s6142 { background-image: url(/assets/img/tabs/6142.jpeg); }
    &.btn-choice-template--collage1 { background-image: url(/assets/img/tabs/collage1.jpeg); }
}

.templates-container {
    flex-shrink: 0;
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 30px;

    .container {
        display: flex;
        flex-direction: row;
        overflow: auto;
        width: 100%;
        max-width: 100%;
        padding: 2px 2px 10px;

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.15);
            height: 6px;
        }

        &::-webkit-scrollbar {
            padding: 10px 0;
            height: 6px;
            background-color: rgba(255, 255, 255, 0.15);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c3e748;
            border-radius: 8px;
        }
    }
}

@media all and (max-width: 1000px) {
    .collage-page .btn-back {
        position: static;
        margin-top: -20px;
        margin-bottom: 30px;
    }
}

@media all and (max-height: 850px) and (orientation: landscape) {
    .main-title {
        font-size: 34px;
    }
    .main-page p {
        font-size: 16px;
        line-height: 1.33;
    }
    .slide {
        width: 300px;
        height: 300px;
        border-width: 8px;
    }

    .wr-slider {
        margin-top: 3vh;
        margin-bottom: 3vh;
    }

    .wr-slider .slick-dots {
        bottom: -30px;
    }

    .wr-slider .slick-dots li {
        width: 5px;
        height: 5px;
        margin: 0 4px;
    }

    .wr-slider .slick-dots .slick-active {
        width: 30px;
    }

    .btn-upload-foto {
        font-size: 16px;
        max-width: 214px;
        height: 48px;
    }
    .net1 {
        width: 124px;
    }
    .net2 {
        width: 105px;
    }
}

@media all and (max-height: 700px) and (orientation: landscape) {
    .net1 {
        width: 124px;
    }
    .net2 {
        width: 105px;
    }
    .loader p {
        font-size: 16px;
        top: calc(40% + 220px);
    }
    
    .spinner-container img {
        width: 140px;
        height: 140px;
    }
    
    .avatar-image {
        width: 140px;
        height: 140px;
    }
    
    .triple-spinner {
        width: 270px;
        height: 270px;
        border-radius: 50%;
        border: solid 2px #dfdfe0;
    }
    
    .triple-spinner2 {
        width: 210px;
        height: 210px;
    }
    
    .triple-spinner::before,
    .triple-spinner2::before  {
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
    }
    
    .triple-spinner::before {
        border: 6px solid #c3e748;
        border-bottom-color: transparent;
        border-right-color: transparent;
    }
    
    .triple-spinner2::before {
        border: 6px solid #c3e748;
        border-top-color: transparent;
        border-left-color: transparent;
    }
}

@media all and (max-height: 660px) {
    .container {
        padding: 15px 15px 0;
    }

    .main-title {
        margin-bottom: 12px;
    }
}

@media all and (max-height: 660px) and (orientation: landscape) {
    .collage-page .creative-holder,
    .tabs-container {
        max-width: 280px;
    }

    .collage-page .container {
        max-width: 310px;
    }
}

@media all and (max-height: 580px) {
    .collage-page .btn-back {
        margin-bottom: 10px;
    }
    .collage-page .creative-holder {
        margin-bottom: 16px;
    }
}

@media all and (max-height: 520px) {
    .collage-page .container {
        padding-top: 12px;
    }
    .collage-page .btn-back {
        margin-bottom: 8px;
    }
    .collage-page {
        padding-bottom: 0;
    }
    .collage-page .creative-holder {
        margin-bottom: 12px;
    }
    .slide {
        width: 220px;
        height: 220px;
    }
}

@media all and (max-width: 770px) {
    .net1 {
        width: 124px;
    }
    .net2 {
        width: 105px;
    }
    .loader p {
        font-size: 16px;
        top: calc(40% + 220px);
    }
    
    .spinner-container img {
        width: 140px;
        height: 140px;
    }
    
    .avatar-image {
        width: 140px;
        height: 140px;
    }
    
    .triple-spinner {
        width: 270px;
        height: 270px;
        border-radius: 50%;
        border: solid 2px #dfdfe0;
    }
    
    .triple-spinner2 {
        width: 210px;
        height: 210px;
    }
    
    .triple-spinner::before,
    .triple-spinner2::before  {
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
    }
    
    .triple-spinner::before {
        border: 6px solid #c3e748;
        border-bottom-color: transparent;
        border-right-color: transparent;
    }
    
    .triple-spinner2::before {
        border: 6px solid #c3e748;
        border-top-color: transparent;
        border-left-color: transparent;
    }

    .main-title {
        font-size: 34px;
    }
    .main-page p {
        font-size: 16px;
        line-height: 1.33;
    }
    .slide {
        width: 300px;
        height: 300px;
        border-width: 8px;
    }

    .wr-slider {
        margin-top: 3vh;
        margin-bottom: 3vh;
    }

    .wr-slider .slick-dots .slick-active {
        width: 30px;
    }

    .wr-slider .slick-dots {
        bottom: -30px;
    }

    .wr-slider .slick-dots li {
        width: 5px;
        height: 5px;
        margin: 0 4px;
    }

    .btn-upload-foto {
        font-size: 16px;
        max-width: 214px;
        height: 48px;
    }
}

@media all and (max-width: 500px) {
    .share-container {
        left: -30px;
        width: calc(100% + 60px);
    }
    .collage-page .creative-holder .imageview-holder {
        border-radius: 0;
    }

    .tabs-container {
        position: relative;
        z-index: 100;
        margin-bottom: 4px;
    }

    .collage-page .btn-upload-foto {
        max-width: 140px;
    }

    .btn-refresh-foto {
        right: 20px;
    }
}

@media all and (max-width: 380px) {
    .main-title {
        font-size: 24px;
    }
    .slide {
        width: 240px;
        height: 240px;
    }
}

@media all and (max-width: 340px) {
    .modal {
        padding: 76px 16px 16px;
    }
}